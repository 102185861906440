<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add Schema
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <!-- <b-col md="6">
            <b-form-group
              label="Type"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >

                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.schemaable_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="typeList"
                  :reduce="(val) => val.id"
                  @input="getCourseSchedule(addCourseForm.schemaable_type)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col

            md="6"
          >

            <b-form-group
              label="Select Schema"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="cate"
                rules="required"
              >

                <v-select
                  id="blog-edit-category"
                  v-model="addCourseForm.schemaable_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="categoriesList"
                  @input="getSchema(addCourseForm.schemaable_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>

          <b-col
            cols="12"
            class="mb-2"
          >

            <div class="border rounded p-2">
              <b-row
                v-for="(itemQ, index) in PostData.mainEntity"
                :key="index"
                no-body
                class="border rounded p-2"
              >

                <br>
                <b-card-text class="my-50" />

                <b-col

                  cols="10"
                  class="mt-50"
                  style="text-align: end"
                >
                  <b-button
                    v-if="index != 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline"
                    class="mr-1"
                    @click="removeQue(index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="Question "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Q "
                    >

                      <b-form-input
                        id="alt-image"
                        v-model="itemQ.name"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label="Answer"
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <b-form-textarea
                      id="alt-text"
                      v-model="itemQ.acceptedAnswer.text"
                    />

                  </b-form-group>
                </b-col>

                <hr>
              </b-row>

              <b-row
                class="text-end"
                style="text-align: end"
              >
                <b-col
                  cols="12"
                  class="mt-50"
                >

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="newQue"
                  >
                    New Faq
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col md="6">

            <!--
        <div v-for="(value, key) in PostData" :key="key">
      <template v-if="typeof value === 'object'">
        <div v-for="(subValue, subKey) in value" :key="subKey">
          {{ subKey }}: {{ subValue }}
        </div>
      </template>
      <template v-else>
        {{ key }}: {{ value }}
      </template>
    </div> -->

          </b-col> </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,

  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,

  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'

import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import Editor from '@tinymce/tinymce-vue'
import store from '@/store'

export default {
  setup(props, { emit }) {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    
    const isLoading = ref(false)
    const coursesList = ref([])
    const categoriesList = ref([])
    const scheduleList = ref([])
    const citiesList = ref([])
    const addCourseForm = reactive({
      category_id: '',
      type: '',
      course: '',
      city: '',
      schedule: '',
      slider: '',
    })
    const dedicated = ref(false)
    // store.dispatch('categories/CategoryList').then(response => {
    //   categoriesList.value = response.data
    // })
    const getCourseSchedule = () => {
      categoriesList.value = []

      store.dispatch('blog/getAllBlog').then(response => {
      // categoriesList.value = response.data.data

        categoriesList.value = response.data.data.map(el => ({
          name: el.title,
          id: el.id,
          schema: el.schema,
          faq: el.faq,
        }))
      })
    }
    getCourseSchedule()
    const getscheduleByCourse = (cityid, courseid) => {
      store
        .dispatch('homepage/getscheduleByCourse', { cityid, courseid })
        .then(response => {
          scheduleList.value = response.data
        })
    }
    const getscheduleByCourseOnline = (cityid, courseid) => {
      if (addCourseForm.type == 'online') {
        store
          .dispatch('homepage/getscheduleByCourse', { cityid, courseid })
          .then(response => {
            scheduleList.value = response.data
          })
      }
    }
    const typeList = [
      {
        name: 'Post',
        id: 'Modules\\DynamicContent\\Entities\\Post',
      },
      {
        name: 'Course',
        id: 'Modules\\Course\\Entities\\Course',
      },

      {
        name: 'Event',
        id: 'Modules\\Event\\Entities\\Event',
      },
    ]

    const getCoursesByCategory = id => {
      let online = null
      if (addCourseForm.type == 'online') {
        online = 1
      } else {
        online = 0
      }
      store
        .dispatch('courses/getAllCoursesList', {
          'filter[category_id]': id,
          'filter[online]': online,
        })
        .then(response => {
          coursesList.value = response.data
        })
    }

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const addCourseFormvalidate = ref()

    const save = () => {
      const formData = new FormData()

      addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append('post_id', addCourseForm.schemaable_id.id)

          formData.append('data', JSON.stringify(PostData.value))

          isLoading.value = true

          store
            .dispatch('homepage/addSchemaFaq', formData)
            .then(response => {
              addCourseForm.schedule = ''
              isLoading.value = false
              PostData.value = {
                '@context': 'https://schema.org',
                '@type': 'FAQPage',
                mainEntity: [
                  {
                    '@type': 'Question',
                    name: '',
                    acceptedAnswer: {
                      '@type': 'Answer',
                      text: '',
                    },
                  },

                ],
              }

              emit('add-course')
              Vue.swal({
                title: ' Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }

    const getSchema = item => {
      console.log(item.faq)
      if (item.faq !== null) {
        console.log("1")
        PostData.value = JSON.parse(item.faq.data)
      } else {
           console.log("2")
        PostData.value.mainEntity=[
        {
          '@type': 'Question',
          name: '',
          acceptedAnswer: {
            '@type': 'Answer',
            text: '',
          },
        },

      ]
      }
    }
    const PostData = ref({
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [
        {
          '@type': 'Question',
          name: '',
          acceptedAnswer: {
            '@type': 'Answer',
            text: '',
          },
        },

      ],
    })

    const removeQue = i => {
      PostData.value.mainEntity.splice(i, 1)
    }
    const newQue = () => {
      PostData.value.mainEntity.push({
        '@type': 'Question',
        name: '',
        acceptedAnswer: {
          '@type': 'Answer',
          text: '',
        },
      })
    }
    return {
      newQue,
      removeQue,
      getSchema,
      isLoading,
      PostData,
      refInputEl,
      dedicated,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      typeList,
      categoriesList,
      getCoursesByCategory,
      citiesList,
      getCourseSchedule,
      scheduleList,
      getscheduleByCourseOnline,
      getscheduleByCourse,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    flatPickr,
    Editor,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,

    vSelect,

  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
